module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Frensies","start_url":"/","background_color":"#000000","theme_color":"#df7c6d","display":"standalone","icon":"static/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b5a70eed51a5e967f6638fadaa3d1a60"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
